import React from 'react'
import {inject, observer} from 'mobx-react'
import { observable } from 'mobx'
//import { translate } from 'react-polyglot'
import { clearCache as clearApiCache } from 'common/services/apiService'
import { setCookie, getCookie } from 'common/utils/cookies'
import ReactInterval from 'react-interval'
import moment from 'moment'
import LoginDialog from 'common/components/LoginDialog'

export default
//@translate()
@inject('accountStore')
@inject('routingStore')
@inject('mainStore')
@observer
class Authenticate extends React.Component {

  @observable isLoginDialogOpened = false
  @observable isOtpPending = false
  @observable showNotMe = false

  componentDidMount() {
    //console.log('Authenticate')    
    setTimeout(() => {
      //console.log('Authenticate (timeout)') 
      this.silentLogin()
    }, 500) //allow accountStore to fetch the profile from state
  }

  silentLogin = () => {
    const { accountStore, mainStore, routingStore: { location: { pathname: path } } } = this.props
    if (!accountStore.profile && !this.isOtpPending) {
      //try to login with cookie, if it exists
      const cook = getCookie('Tenders_Autologin')
      if (cook) {
        //console.log('Authenticate', cook)        
        const arrUser = cook.split('|||')
        accountStore.login(arrUser[0], arrUser[1], true).then(() => {
          //console.log('Authenticate (login)', res)
          if (accountStore.error == null && accountStore.profile != null) {
            localStorage.setItem('tenders_NeedOtp', res.NeedOtp)
            localStorage.setItem('tenders_OtpDestinationDef', res.OtpDestination)
            clearApiCache()
            if (accountStore.profile.LoggedFromIfat) {
              //if login was successful, set here the last otp also, to localStorage
              //(avoid logging out loop when otp is not needed e.g. from Ifat)
              const lastOtp = moment().format('YYYY-MM-DD HH:mm:ss')
              localStorage.setItem('tenders_lastOtpDate', lastOtp)
            }
            if (path.indexOf('/main') > -1) {
              mainStore.loadAgentResults2()
            }
          }
          else if (accountStore.error == null && accountStore.otpStatus?.ok) {
            //save a short expiration for the otp view. if login will be successful there, it will save real Tenders_Autologin cookie
            //console.log('send otp ... ');
            setCookie('Tenders_Autologin_Otp', `${arrUser[0]}|||${arrUser[1]}`, { expires: 1, sameSite: 'lax' })
            this.isOtpPending = true  //set flag so timer will not continue sending sms messages
            this.isLoginDialogOpened = true
          }
        })
      }
    }
  }

  checkOtpTime = async () => {
    console.log('otp timer')
    const otpDate = localStorage.getItem('tenders_lastOtpDate')
    const needOtp = localStorage.getItem('tenders_NeedOtp')
    const isOtpNeeded = (needOtp !== 'false')
    //console.log('isOtpNeeded', isOtpNeeded);
    if (otpDate) {
      const lastOtpDate = moment(otpDate, 'YYYY-MM-DD HH:mm:ss')
      console.log(lastOtpDate);
      if (isOtpNeeded && lastOtpDate.isBefore(moment().subtract(1, 'days'))) {
        //more than one day has passed since last otp
        console.log('need otp');
        const { accountStore } = this.props
        if (!this.isOtpPending) {
          if (accountStore.profile) {
            await accountStore.logout(true)  //must log user out to enable re-login and check otp if needed
          }
          this.silentLogin()
        }
      }
      else {
        //console.log('otp not needed');
      }
    }
  }

  validate = () => {  
    /* refresh the bearer token. authStore will save new token to localStorage */  
    //console.log('validate')
    const {props: {accountStore}} = this
    if (accountStore.profile) {
      //only if there is a profile present (user is logged)
      accountStore.refreshToken()
    }
  }

  continueUnlogged = () => {
    this.isLoginDialogOpened = false
    this.isOtpPending = false
  }

  render() {
    //const {t} = this.props
    return (
      <span>
        <ReactInterval timeout={60000 * 60} enabled={true}
          callback={() => this.validate()} />

        <ReactInterval timeout={300000} enabled={true}
                callback={() => this.checkOtpTime()} />
        {this.isLoginDialogOpened && <LoginDialog onCancel={this.continueUnlogged} isOtp={true} />}
      </span>
    )
  }
}