export default {
  page404: "מצטערים, הקישור שגוי",
  loading: "מעדכן ...",
  AppTitle: "מכרזים",
  newVersion:
    "לקוח יקר, עודכנה גירסה חדשה של האתר. בכדי לראות את השינויים יש ללחוץ על  ",
  reload: "ריענון",
  login: {
    usernameLabel: "שם משתמש",
    passwordLabel: "סיסמה",
    rememberMe: "זכור לפעם הבאה",
    login: "התחבר",
    subscribeTitle: "המידע למנויים בלבד",
    subscribeSubTitle: "המכרז זמין רק למנויים, לצפיה התחבר או רכוש מנוי",
    subscribe: "מעוניינים להמשיך ולצפות בעוד מכרזים והזדמנויות עסקיות בתחומך?",
    buy: "רכישת מנוי",
    benefit1: "הטבות בלעדיות לנרשמים דרך האתר",
    benefit2: "מגוון מסלולים רחב המתואם לכל עסק",
    register: "להרשמה",
    forgotPassword: "שכחת סיסמא?",
    forgotPasswordEx: "לא מצליח להיכנס? נשלח לך מייל עם לינק לשחזור",
    emailLabel: "כתובת מייל לשחזור",
    restore: "שחזר",
    backToLogin: "חזרה למסך התחברות",
    sent: "שלחנו מייל לשחזור סיסמה לכתובת %{email}",
    noSuchUser: "הכתובת לא נמצאת במערכת, אנא פנה לשרות לקוחות 03-3095247",
    restoreTitle: "עדכון סיסמה",
    confirmPassword: "אישור סיסמה",
    missingPassword: "יש להזין סיסמה",
    missingConfirmation: "יש לאשר את הסיסמה שהוזנה",
    noPasswordMatch: "הסיסמאות שהוזנו לא תואמות",
    restored: "הסיסמה שוחזרה",
    notRestored: "03-3095247",
    tokenNotValid:
      'פג תוקף הבקשה. יש לבקש שיחזור מחדש דרך "שכחתי סיסמא" במסך ההתחברות',
    badToken:
      "הועבר מספר זיהוי לא תקין של הבקשה, או שחלה שגיאה אחרת. יש לפנות לשירות",
    pleaseLogin: "יש להתחבר למערכת. אם קיבלת הודעת התחברות וסגרת את חלונית ההתחברות, יש לרענן את הדף"
  },
  loginForm: {
    title: "התחבר למערכת",
    userName: "שם משתמש",
    password: "סיסמה",
    remember: "זכור אותי",
    forgot: "שכחתי את הסיסמה",
    login: "התחברות",
    badUserPass: "שם משתמש או סיסמה שגויים",
    badPhoneNumber: 'מס\' הטלפון שמעודכן במערכת אינו תקין. אנא פנו לשירות לקוחות',
    subTitle1: "SMS יש להזין את קוד האימות שקיבלתם באמצעות",
    subTitle2: "‏05XXX-X-למס' טלפון 7342",
    copyRight: "כל הזכויות שמורות ליפעת מכרזים © ‏2021",
    purchase: "רכישת מנוי",
    sendOtpByEmail: "שליחת קוד חד פעמי במייל",
    willSendSms: "אם לא מופעל, הקוד ישלח ב-SMS במידת הצורך",
  },
  forgotPassword: {
    title: "שכחת סיסמא?",
    userName: "שם משתמש",
    emailLabel: "כתובת מייל לשחזור",
    backToLogin: "חזרה למסך התחברות",
    restore: "שחזר",
  },
  otp: {
    detailsTitle: "הוספת פרטי התקשרות",
    missingDetailsEx:
      "שלום %{name}, לשם הגנה על פרטיות המידע שלך ברצוננו לשלוח לך קוד אימות",
    missingDetailsPhone: "יש להזין מספר טלפון נייד",
    missingDetailsEmail: "יש להזין כתובת מייל",
    backToLogin: "חזרה למסך התחברות",
    addDetails: "שלח פרטים",
    notMyNumber: "זה לא המספר שלי",
    notMyEmail: "זה לא המייל שלי",
    notMeTitle: "שינוי פרטי התקשרות",
    notMeEx:
      "שלום %{name}, אם ברצונך לשנות את המייל שלך או מספר הטלפון שלך - אנו מזכירים לך כי המנוי הוא אישי",
    notMe: "שלח בקשה",
    sentSuccessfully: "הבקשה נשלחה בהצלחה!",
    sentError: "חלה תקלה במשלוח פרטים לעדכון",
    otpTitle: "אימות ב-%{sentDestination}",
    sendOtpEx: "אנא הזן קוד אימות שנשלח למספר הטלפון איתו נרשמת",
    sendOtpExEmail: "אנא הזן קוד אימות שנשלח לכתובת המייל איתה נרשמת",
    sendOtp: "שלח קוד",
    login: "התחבר",
    enterPhone: "יש להזין מספר טלפון",
    phoneNotValid: "יש להזין מספר טלפון תקין",
    enterEmail: "יש להזין כתובת מייל",
    emailNotValid: "יש להזין כתובת מייל תקינה",
    enterCode: "יש להזין קוד אימות",
    resendCode: "שלחו לי שוב",
    resendByEmail: "שלחו לי קוד במייל",
    otpSentAgain: "הקוד נשלח",
    otpSentError: "חלה תקלה במשלוח קוד חדש",
    didNotReceiveMessage: "לא קיבלתי הודעה?",
  },
  loginSubscribe: {
    listItem1: "שימוש חופשי באפליקציית מכרזים",
    listItem2: "ליווי אישי ומקצועי בהגשת מכרז",
    listItem3: "הטבות בלעדיות לנרשמים באתר",
    listItem4: "עבודה עם המומחים המובילים בתחומך",
    title: "רכישת מנוי",
    subTitle:
      "מעוניינים להמשיך ולצפות בעוד מכרזים ולקבל הזדמנויות עסקיות שמתאימות בדיוק %{a}?",
    subscribe: "לרכישת מנוי",
  },
  nav: {
    logoAlt: "יפעת מכרזים - פרסום מכרזים והזדמנות עסקיות",
    home: "עמוד הבית",
    about: "אודות",
    termsOfUse: "מדיניות פרטיות",
    qanda: "שאלות ותשובות",
    subscriptions: "מסלולים",
    services: "מוצרים ושירותים",
    smartagent: "הגדרות סוכן",
    contactus: "צור קשר",
    publish: "פרסום מכרז חדש+",
    favorites: "מועדפים",
    reminders: "תזכורות",
    pleaseLog: "התחברות/הרשמה",
    logout: "התנתק",
    search: "חיפוש",
    ifatAgent: "הגדרות סוכן (יפעת)",
    newEntrants: "מצטרפים חדשים",
    serviceTimings: `שירות הלקוחות שלנו כאן בשבילכם ופעיל בימים א'-ה' בין השעות 08:00-17:30`,
    customerService: "שירות לקוחות",
    technicalSupport: "תמיכה טכנית",
    articles: "בלוג מאמרים",
    siteMap: "מפת האתר",
    publish: "פרסום מכרז חדש",
  },
  confirm: {
    cancel: "בטל",
  },
  home: {
    mainTitle: "רק אנחנו נמצא את",
    mainTitle2: "ההזדמנויות המתאימות עבורך",
    subTitle: "השאירו פרטים ונחזור אליכם עם הצעה משתלמת",
    catTitle: "כל המכרזים, כל התחומים",
    showAllCat: "כל הקטגוריות",
    hideAllCat: "סגור",
    opportunities: "ההזדמנויות זורמות אליך בכל דרך שתבחר",
    opportunitiesSub:
      "יפעת מכרזים הינו מאגר מכרזים הגדול במדינה הכולל: מכרזים פומביים, מכרזי פטור, מכרזים בלעדיים, מכרזי כונס, תוצאות מכרזים ועוד...",
    testemonials: "לקוחות מספרים",
    lastTenders: "מכרזים אחרונים",
    articles: "בלוג מאמרים",
    readMore: "קרא עוד...",
    articlesText: "תכנים שהצוות המקצועי שלנו כתב ואסף עבורכם",
    allArticles: "לכתבות ומאמרים נוספים",
    movies: "יפעת מכרזים מלווה אותך בעסקים",
    leadingIndustries: "הענפים המובילים",
    viewAllAreas: "לצפייה בכל התחומים",
    recentTenders: "מכרזים אחרונים",
    latestTendersText: "אספנו עבורך כמה מהמכרזים האחרונים שעלו לאתר שלנו",
    viewMoreTenders: "לצפיה במכרזים נוספים לוחצים כאן",
    lastWinners: "זוכים אחרונים",
    congratulationsText: "מברכים את הזוכים האחרונים במכרזים מובילים",
    ourCustomers: "הלקוחות שלנו מספרים",
    text: "סיימנו לדבר על עצמנו.. הנה מה שהלקוחות שלנו חושבים עלינו",
    newOnSite: 'חדש באתר'
  },
  meta: {
    homeTitleNew: "יפעת מכרזים, מכרזים, מכרזים פומביים, פרסום מכרזים, מאגר מכרזים",
    homeTitle:
      "יפעת מכרזים: פרסום מכרזים והזדמנויות עסקיות | מאגר מכרזים המוביל בישראל!",
    homeDesc:
      "אתר המכרזים למגזר העסקי הגדול בישראל המרכז עבורכם מכרזים בלעדיים ומכרזים פומביים בכל התחומים וצוות מומחים מנצח שילווה אותך לאורך כל התהליך.",
    homeKeywords:
      "יפעת מכרזים, מכרזים, מכרזים פומביים, פרסום מכרזים, מאגר מכרזים",
    catResultsTitle:
      "מכרזי %{tag} פומביים ובלעדיים | מכרזים ל%{tag} מוצאים רק ביפעת מכרזים!",
    catResultsDesc:
      "יפעת מכרזים מרכזת עבורכם מכרזי %{tag} בלעדיים ופומביים. הצטרפו גם אתם לעסקים וארגונים שמקבלים מידע ייחודי על מכרזים ל%{tag} והזדמנויות עסקיות נוספות בתחום.",
    catKeywords: "מכרזי %{tag}, מכרזים ל%{tag}",
    pageTitle: "%{pageName} - יפעת מכרזים",
    pageDesc:
      "%{pageName} - יפעת מכרזים : אתר המכרזים למגזר העסקי הגדול בישראל המרכז עבורכם מכרזים בלעדיים ומכרזים פומביים בכל התחומים!",
    pageKW: "%{pageName}",
    subscriptions: "מסלולים ומחירים",
    services: "מוצרים ושרותים",
    contact: "צור קשר",
    articles: "כתבות",
    restore: "שחזור סיסמה",
    feedback: "יפעת מכרזים | סקר שביעות רצון",
    aboutTitle:"אודות יפעת מכרזים המספקת פרסום של מכרזים ויצירת הזדמנויות עסקיות",
    aboutDesc: "יפעת מכרזים מובילה את שוק המכרזים וההזדמנויות העסקיות בישראל זה כשלושים שנה, ומעסיקה עשרות מידענים ומנהלי תיקי לקוחות המספקים שירות אישי ומותאם ללקוח.",
    aboutKeywords:
      "יפעת מכרזים, מכרזים, מכרזים פומביים, פרסום מכרזים, מאגר מכרזים",
    
  },
  about: {
    title: "יפעת מכרזים",
    subTitle: "פותחים עוד ועוד דלתות בפניך",
  },
  cat: {
    opportunities: "הזדמנויות עיסקיות",
    viewAll: "רוצה לראות את כל ההזדמנויות העיסקיות עבורך",
    leaveDetails: "השאירו פרטים ונחזור אליך עם הצעה משתלמת",
    sent: "הפניה נשלחה! ניצור איתכם קשר בקרוב, תודה !",
    name: "שם",
    email: "אימייל",
    phone: "מספר טלפון",
    subTitle:
      'רוצים להיחשף בזמן אמת לכל סוגי המכרזים המתקיימים ע"י %{short}? רוצים להגדיל את סיכוייכם לקבלת מכרז? זה הזמן לדעת הכל על רשימת מכרזים %{short}',
    contactTitle: "לפרטים נוספים אודות שירותי יפעת מכרזים בתחום %{title}",
  },
  main: {
    greet: "שלום %{user},",
    title: "מצאנו לך %{count} הזדמנויות עסקיות חדשות",
    title2: "הזדמנויות עסקיות מהסוכן החכם",
    moreTenders: "מכרזים נוספים עבורך:",
    noResults: "לא נמצאו עבורך הזדמנויות עסקיות חדשות",
  },
  search: {
    placeHolder: "חפש לפי קטגוריה או מפרסם או שם המכרז",
    noResults: "אין תוצאות להצגה",
    area: "איזור",
    city: "עיר",
    subject: "ענף",
    subsubject: "תת ענף",
    tendertype: "סוג",
    publisher: "מפרסם",
    daysBack: "טווח זמן",
    tender_partial: "בטקסט של מכרז",
    tender_partial_text: "%{text}",
    publisher_partial: "בטקסט של מפרסם",
    publisher_partial_text: "%{text}",
    cleanSearch: "נקה חיפוש",
  },
  results: {
    title: "הזדמנויות עיסקיות נמצאו",
    selectAll: "בחר הכל",
    sortBy: "מיין לפי",
    infoDate: "תאריך הגשה",
    publishDate: "תאריך פרסום",
    noData: "אין תוצאות להצגה",
    lastMonth: "בחודש האחרון",
    lastWeek: "בשבוע האחרון",
    lastYear: "בשנה האחרונה",
    login: "יש להתחבר למערכת",
    error: "חלה שגיאה בחיפוש, אנא נסה שנית",
    extraData: "(כמנוי לכל הסיווגים, תקבל %{count})",
    preTitle: "מכרזי  %{catLabel}",
    saleText:
      "יפעת מכרזים מרכזת עבורכם מכרזי %{subSubject} פומביים ובלעדיים והזדמנויות עסקיות נוספות בתחום. החברה המובילה בישראל כבר למעלה משלושים שנה מעסיקה עשרות מידענים ומנהלי תיקי לקוחות המספקים שירות אישי ומותאם ללקוח. לקבלת מידע על מכרזים ל%{subSubject} השאירו פרטים ונציג מטעמנו ידאג לחזור אליכם בהקדם.",
    totalOpportunitiesText: "!מצאנו %{count} הזדמנויות עסקיות עבורך",
    filters: "מסננים",
    filtersPopupTitle: "מסננים וממיינים",
    closeFiltersPopup: "סנן וסגור",
    filterSubtitle: "המסננים יעזור לך להציג את התוצאות המתאימות ביותר עבורך",
    totalFilters: "%{count} מסננים פעילים",
    totalResults: "מצאנו %{count} הזדמנויות עסקיות עבורך!",
  },
  tender: {
    tenderType: "סוג מכרז:",
    publishedAt: "פורסם ב",
    deliveryAt: "הגשה ב",
    tourAt: "סיור קבלנים ב",
    addToFav: "הוסף למועדפים",
    removeFromFav: "הסר ממועדפים",
    noDate: "לא פורסם",
    exclusive: "בלעדי",
    twoDaysLeft: "הגשה בעוד יומיים",
    oneDayLeft: "הגשה בעוד יום",
    noDaysLeft: "הגשה היום",
    twoDaysLeftTour: "סיור קבלנים בעוד יומיים",
    oneDayLeftTour: "סיור קבלנים בעוד יום",
    noDaysLeftTour: "סיור קבלנים היום",
    mustDoTour: "סיור חובה",
    mustTour: "חובה",
    publisherTitle: "מפרסם המכרז",
    publisher: "שם המפרסם:",
    publisherSite: "אתר המפרסם:",
    delivery: "מועד ההגשה:",
    tourDetails: "סיור קבלנים:",
    tenderConditions: "תנאי סף למכרז:",
    presentationPlace: "הערות הגשה:",
    subjects: "ענפים:",
    subSubjects: "תתי ענף:",
    details: "פרטים",
    extraData: "פרטים נוספים",
    comment: "פרטים נוספים",
    commentLabel: "הערה",
    originalTitle: "המכרז המקורי:",
    clickHere: 'לחצ.י כאן',
    viewImage: "צפייה במודעה",
    links: "מסמכים מקושרים למכרז",
    toTenderDetails: "הורדת מסמכים",
    openRadarInfo: 'פתח מידע מרדאר',
    print: "הדפסה",
    printImage: "הדפסת מודעת מכרז",
    email: "שלח במייל",
    remind: "צור התראה",
    addReminder: "הוסף תזכורת",
    didLike: "מה דעתך על המכרז?",
    didLikeSub: "כך נתאים טוב יותר את המידע עבורך",
    liked: "קלעתם בול!",
    disliked: "פחות מתאים לי",
    sentFeedback: "תודה על פנייתך",
    errors: "חלה שגיאה בטעינת פרטי המכרז.",
    radar: "לליווי אישי ומקצועי ועזרה בהגשת המכרז",
    radarNew: "לחצו לקבלת ליווי אישי בהגשת המכרז",
    iso_del:
      '<span style="color: #4848f3">שימו לב</span>במכרז זה הנכם נדרשים ל<span style="color: #4848f3">תקן ISO.</span>להתייעצות ועזרה מקצועית לקבלת תקן ה-ISO לחצו כאן,<br /> או התקשרו ל-073-7760010',
    iso: '<span style="color: #4848f3;font-weight: bold">שימו לב </span>במכרז זה הנכם נדרשים <br /><span style="color: #4848f3;font-weight: bold">לתקן ISO. </span><br />להתייעצות ועזרה מקצועית לקבלת ה-ISO לחצו כאן, <br />או התקשרו ל-073-7760010',
    tenderPublicLabel: "פומבי/כללי",
    subscribeText1: "אינך מחובר למערכת, או שהמכרז אינו משוייך אליך.",
    subscribeText2:
      "אנא נסה להתחבר למערכת. אם הינך מחובר כבר, אנא צור קשר אם ברצונך לצפות במכרז.",
    contact: "ליצירת קשר",
  },
  publish: {
    titleSection1: "פרטי המכרז",
    titleSection2: "פרטים אישיים",
    title: "פרסם מכרז",
    firstName: "שם פרטי",
    lastName: "שם משפחה",
    companyName: "חברה",
    companyPhone: "טלפון חברה",
    toDate: "תאריך הגשה",
    toTime: "שעת הגשה",
    email: "אימייל",
    phone: "טלפון",
    fax: "פקס",
    address: "כתובת",
    tenderTitle: "כותרת",
    tenderDesc: "פרטים",
    submit: "פרסם מכרז",
    enterName: "יש להזין שם פרטי",
    enterLastName: "יש להזין שם משפחה",
    enterCompanyName: "יש להזין שם חברה",
    enterEmail: "יש להזין כתובת מייל",
    enterPhone: "יש להזין מספר טלפון",
    enterTitle: "יש להזין כותרת למכרז",
    enterDesc: "יש להזין את פרטי המכרז",
    emailNotValid: "יש להזין מייל תקין",
    phoneNotValid: "יש להזין מספר טלפון תקין",
    faxNotValid: "יש להזין מספר פקס תקין",
    sentSuccessfully: "פרטי המכרז נשלחו בהצלחה",
    willCall: "ניצור איתך קשר בהקדם",
    toHome: "לעמוד הבית",
    promo: "נמאס לך מהחיפוש המתיש אחרי ספקים, נותני שירותים ויצרנים?",
    promoDetails:
      "הישען לאחור, הירגע... ותן להם לרדוף אחריך! גם אתה יכול לפרסם מכרזים, לאתר ספקים ולמצוא נותני שירות והכול בקלות וללא עלות! עשרות ספקים ונותני שירות מחכים לך כבר עכשיו",
  },
  radarInfo: {
    unAuthorized: 'אינך מורשה להיכנס לדף זה',
    validTenderID: 'אנא ספק מזהה מכרז תקף',
    resultsFound: 'נמצאו %{count} תוצאות',
    noResultFound: 'לא נמצאו תוצאות',
    userDate: 'תאריך משתמש',
    subSubjects: 'נושאים',
    publisherName: 'מפרסם',
    customerPhone: 'טלפון לקוח',
    cellular: 'נייד',
    personName: 'שם איש קשר',
    contactEmail: 'אימייל איש קשר',
    customerEmail: 'אימייל לקוח'
  },
  contact: {
    title: "צור קשר",
    callUs: "השאירו פרטים ונחזור אליכם בהקדם",
    firstName: "שם מלא",
    email: 'כתובת דוא"ל',
    phone: "טלפון",
    why: "מהות הפניה",
    submit: "שלח",
    service: "שירות לקוחות",
    techsupport: "תמיכה טכנית",
    sales: "מכירות",
    smallTitle: "רוצה לראות את כל ההזדמנויות העיסקיות עבורך?",
    smallSubTitle: "השאירו פרטים ונחזור אליכם עם הצעה משתלמת",
    sent: "הפניה נשלחה! ניצור איתכם קשר בקרוב, תודה !",
    success: "הודעתך נשלחה בהצלחה!",
    willCall: "ניצור איתך קשר בהקדם",
    toHome: "לעמוד הבית",
    radar: "פניה מדף רדאר",
    subscribe: "הצטרפות וחידוש מנוי",
    service: "שירות לקוחות ותמיכה טכנית",
    other: "אחר",
  },
  toolbar: {
    selectedTenders: "מכרזים נבחרו",
    email: "שלח במייל",
    print: "הדפס",
    printBig: "הדפס מודעות מכרזים",
    fav: "הוסף למועדפים",
    emailSubject: "משלוח עבורך מיפעת מכרזים",
    emailBody:
      "לצפיה במכרזים, לחצו כאן: https://www.tenders.co.il/distagent/%{uid}",
    text: "ביצוע פעולה על פריטים מסומנים",
    totalSelectedTenders: "%{count} פריטים סומנו",
    favTooltip: "הוסף למועדפים",
    addReminderTooltip: "הוסף תזכורת",
    printTooltip: "הדפס",
    printBigTooltip: "הדפס מודעות",
    mailTooltip: "שלח במייל",
    infoButtonsTitle: " שירותים משלימים שעשויים לעניין אותך",
  },
  reminder: {
    title: "הוספת תזכורת",
    subject: "נושא",
    today: "היום",
    date: "תאריך",
    time: "שעה",
    delivery: "הגשה ב-%{infoDateVal}",
    noDate: "לא פורסם",
    remark: "הערה",
    email: "מייל לתזכורת",
    cancel: "בטל",
    delete: "מחק",
    submit: "שמור תזכורת",
    linkToItem: "קישור למכרז",
    deleteTitle: "מחיקת תזכורת",
    deleteSubTitle: "האם אתה בטוח שברצונך למחוק את התזכורת?",
    emailRequired: "נא להזין מייל",
    emailValid: "נא להזין אימייל חוקי",
  },
  filter: {
    title: "הגדרות סינון",
    subSubjectsTitle: "בחר ענפים",
    tenderTypeTitle: "סוג מכרז",
    publishersTitle: "בחר מפרסמים",
    infoDate: "תאריך הגשה",
    publishDate: "תאריך פרסום",
    from: "מ",
    to: "עד",
    start: "התחלה",
    end: "סיום",
    today: "היום",
    edit: "ערוך",
    choose: "בחר",
    cancel: "בטל",
    clean: "נקה",
    selectAll: "בחר הכל",
    clearAll: "נקה הכל",
    more: "...עוד",
    searchText: "סנן בתוצאות",
    search: "חפש",
    selectAll: "בחר הכל",
    noData: "לא נמצאו תוצאות",
    lastDay: "יום אחרון",
    lastWeek: "שבוע אחרון",
    lastMonth: "חודש אחרון",
    nextDay: "עד מחר",
    nextWeek: "השבוע הקרוב",
    nextMonth: "החודש הקרוב",
    subSearchPopupTitle: "כל הענפים",
    activeFilter: "מסננים פעילים",
    tenderTypesTitle: 'אילו סוגי מכרזים להציג לך?',
    datesTitle: 'שנציג לך מכרזים בין תאריכים מסויימים?',
    excludeTitle: 'להחריג משהו?',
    excludeHR: 'ללא משרות דרושים',
    excludeMod: 'ללא מכרזי משרד הבטחון'
  },
  favorites: {
    title: "מועדפים נמצאו",
  },
  reminders: {
    title: "תזכורות",
    infoDate: "ת. הגשה",
    today: "היום",
    back: "חזור לתזכורות",
    edit: "ערוך",
    delete: "מחק",
  },
  agent: {
    title: "הגדרות סוכן חכם",
    title1: "החשבון שלי",
    reminderTime: "מועדי קבלת התראות",
    destination: "יעדי קבלת ההתראות",
    queries: "שאילתות הסוכן",
    extensions: "הרחבות",
    excludeHR: "החרגה של משרות דרושים",
    excludeMod: "החרגה של משרד הבטחון",
    branch: "ענף",
    words: "מילים",
    definitionTitle: "עריכת הגדרה",
    infoTypes: "סוגי מידע",
    email: "מייל",
    phone: "טלפון",
    submit: "שמור הגדרות",
    sentSuccessfully: "ההגדרות עודכנו בהצלחה",
    definitionSubmit: "שמור הגדרה",
    cannotSaveDefinition: "לא ניתן לשמור את ההגדרה",
    duplicateDefinition: "קיימת כבר הגדרה לאותו ענף",
    enterEmailOrPhone: "יש להזין מייל או מספר טלפון נייד",
    emailNotValid: "יש להזין מייל תקין",
    phoneNotValid: "יש להזין מספר טלפון תקין",
    placeHolder: "בחר...",
    cancel: "בטל",
    save: "שמור",
    add: "הוסף",
    edit: "ערוך",
    delete: "מחק",
    readOnly: "ניתן לערוך את הנתונים בעזרת מחלקת התמיכה בלבד",
    estimate: "הצג כמויות צפויות",
    submitCounts: "בדוק",
    estimatedCount: "הכמות הצפויה לפי ההגדרות היא %{estimatedCount}",
    checkWords: "בדיקת מילים לחיפוש",
    word: "מילה לחיפוש",
    compareTo: "טקסט להשוואה",
    submitText: "בדוק",
    userSubTitle: "פרטי בית העסק",
    exclude: "החרג"
  },
  agentview: {
    userName: 'שם המנוי',
    address: 'כתובת לחשבונית',
    companyNumber: 'ע.מ./ח.פ',
    updateDetails: 'רוצים לעדכן פרטים?',
    contactUs: 'צרו איתנו קשר',
    alerts: 'התראות',
    definitions: 'הגדרות עוזר אישי',
    generalSearch: 'חיפוש כללי',
    extensions: 'הרחבות',
    excludeHR: 'החרגה של משרות דרושים',
    excludeMod: 'החרגה של משרד הבטחון'
  },
  distagent: {
    wait: "אנא המתן...",
    titlePart1: "מצאנו עבורך",
    titlePart2: "פרטי מידע חדשים",
  },
  searches: {
    title: "חיפושים אחרונים:",
  },
  subscriptions: {
    title: "מסלולים ומחירים",
    onecat: "סיווג אחד",
    twocat: "שני סיווגים",
    allcat: "כל הסיווגים",
    catPrice: "₪ / לחודש",
    catCondition: 'המחיר בהצטרפות לשנה (לא כולל מע"מ)',
    catConditionPartial: 'המחיר בהצטרפות לחצי שנה (לא כולל מע"מ)',
    showPartial: "הצג מחיר לתקופה של חצי שנה",
    showFull: "הצג מחיר לתקופה של שנה",
    benefit1: "חשיפה ל 100% מכרזים פומביים",
    benefit2: "תוצאות מכרזים",
    benefit3: "מכרזים בלעדיים",
    benefit4: "פטור ממכרז",
    benefit5: "סוכן חכם",
    benefit6: "ליווי בהגשת מכרז ללא עלות",
    benefit7: "שירות ותמיכה מלאים",
    benefit8: "אפליקציה",
    register: "הצטרפו עכשיו!",
  },
  services: {
    title: "מוצרים ושרותים",
  },
  articles: {
    title: "כתבות",
  },
  radar: {
    title: "יפעת רדאר מכרזים",
    subTitle: "תסייע לך להגדיל את סיכוייך לזכות במכרז!",
  },
  redirector: {
    pleaseWait: 'אנא המתינו...',
    clickError: 'חלה שגיאה'
  },
  footer: {
    // rights: "כל הזכויות שמורות ליפעת מכרזים",
    rights: "Ⓒ כל הזכויות שמורות ליפעת מכרזים ",
    serviceTitle: "לשרות ותמיכה",
    serviceTitle1: "לשרות ותמיכה: 03-5635000",
    about: "אודות",
    subscriptions: "מסלולים",
    services: "מוצרים ושירותים",
    login: "כניסה למנויים",
    contact: "צור קשר",
    linkTitle: "ראשי",
    publishers: "מכרזים לפי גופים",
    gov: "ממשלה",
    security: "משרד הביטחון",
    kkl: "מנהל מקרקעי ישראל",
    edu: "משרד החינוך",
    elc: "חברת החשמל",
    police: "משטרה",
    nezivut: "שירות המדינה",
    helth: "משרד הבריאות",
    welfare: "משרד הרווחה",
    house: "משרד הבינוי והשיכון",
    categories: "מכרזים לפי קטגוריות",
    transport: "מכרזי תחבורה",
    building: "מכרזי בניה",
    land: "מכרזי מקרקעין",
    assets: "מכרזי נכסים",
    electricity: "מכרזי עבודות חשמל",
    transport: "מכרזי הסעות",
    cars: "מכרזי רכב",
    sitemap: "מפת אתר",
    tenders: "מכרזי",
    contact: "צור קשר",
    sales: "מכירות",
    service: "שירות לקוחות",
    support: "תמיכה טכנית",
    privacyPolicy: 'מדיניות פרטיות',
    qanda: 'שאלות ותשובות',
    localAuthority: 'מכרזי הרשויות המקומיות',
    beerSheva: 'מכרזים עיריית באר שבע',
    hodHasharon: 'מכרזים עיריית הוד השרון',
    nativot: 'מכרזים עיריית נתיבות',
    negevFields: 'מכרזים מועצה אזורית שדות נגב',
    ramatHasharon: 'מכרזים עיריית רמת השרון',
    sakhnin: 'מכרזים עיריית סכנין',
    freight: 'מכרזי הובלות',
    lottery: 'מפעל הפיס מכרזים',
    earthworks: 'מכרזים לעבודות עפר',
    jointsIsrael: "מכרזי ג'וינט ישראל",
    graphicDesign: 'מכרז עיצוב גרפי',
    unitedHealthInsurence: 'קופת חולים מאוחדת מכרזים',
    nationalHealthInsurence: 'קופת חולים לאומית מכרזים',
  },
  qanda:{
    title:'שאלות ותשובות',
  },
  sitemap: {
    publishers: "מכרזים לפי גופים",
    categories: "מכרזים לפי תחומים",
    general: "כללי",
  },
  instructions: {
    app: "אתר מותאם למובייל",
    experts: "מומחים מובילים",
    helper: "נעם. העוזר האישי שלך.",
    escort: "ליווי אישי ומקצועי",
    person1: 'עו"ד אהוד גרא',
    person1Sub: "דיני מכרזים",
    person2: "מירב",
    person2Sub: "דיני מכרזים",
    person3: "אחיקם שוסטר",
    person3Sub: "יעוץ לענף הבניה",
    appDownloadTitle:
      "הישארו מעודכנים גם מהנייד באמצעות אתר מותאם למובייל וקבלו בזמן אמת לנייד עדכונים על מכרזים והזדמנויות עסקיות בתחומכם.",
    appDownload: "לחצו מטה להורדת האפליקציה",
    consultants:
      "יפעת מכרזים מייצרת לך את סביבת עבודה הנוחה ביותר הכוללת מגוון יועצים:",
    freeCall: "פנייה טלפונית ללא תשלום",
    helperEx:
      'בעזרת העוזר האישי שלך תתתבצע עבודת "איתור חכם" של הזדמנויות עסקיות הרלוונטיות לתחום עיסוקך. כל זאת מתבצע בעזרת טכנולוגית מידע מתקדמת אשר מאפשר לנו למקסם את המידע המאותר והמוצג לך',
    helperJoin: "לוחצים כאן להצטרפות לשירות",
    opportunities: "הזדמנויות עסקיות שרודפות אחריך",
    opportunitiesSub:
      "יפעת מכרזים הינו מאגר המכרזים הגדול במדינה הכולל: מכרזים פומביים, מכרזי פטור מכרזים בלעדיים, מכרזי כונס, תוצאות מכרזים ועוד",
    click: "לוחצים ומצטרפים",
    moreDetails: "לקבלת פרטים נוספים",
  },
  bottomBar: {
    assistant: "העוזר האישי",
    search: "חיפוש",
    favourites: "מועדפים",
    reminders: "תזכורות",
  },
  privacyPolicy: {
    terms: "תקנון",
    readTheDocumentCarefully: `גלישתך ושמושך באתר הנם מרצון ומעידים על הסכמתך לתנאי השימוש שיפורטו להלן.
    אנא קרא מסמך זה בעיון ובמידה ואינך מסכים לתנאי השימוש הנך מתבקש באדיבות
    להפסיק את הגלישה באתר ולהימנע מביצוע רכישה באתר:`
  },
  feedback: {
    title: "יפעת מכרזים",
    subTitle: "בודקים מה איתך",
    secSubTitle: "אנחנו מסכמים 3 חודשים של עבודה משותפת ורצינו לוודא שהכל בסדר :)",
    questions: "על מנת שנוכל לשפר את החוויה שלך, נשמח אם תענה על 3 שאלות קצרות:",
    question1: "האם התחברת למערכת שלנו?",
    question2: "בנו עבורך סוכן חכם?",
    question3: "מצאת מכרזים ו/או הזדמנויות שמעניינות אותך?",
    question3Ex: "נשמח לשמוע למה המכרזים/הזדמנויות לא עניינו אותך",
    maxLength: "עד 200 תווים...",
    yes: "כן",
    no: "לא",
    submit: "שלח אלינו",
    prosTips: "אם כבר התרגלת לעבוד אתנו הגיע הזמן לטיפים של מקצוענים",
    prosSubTip: "פעם בחודשיים אנחנו מקיימים מפגש לקוחות בזום בכדי, להרחיב לך כלים בעולם המכרזים ולענות על שאלות",
    prosSub1: "הצטרפו למפגשי",
    prosSub2: "אחת לחודשיים והרחיבו את סל הכלים בעולם המכרזים",
    addProsEmail: "הזן מייל ונרשום אותך להדרכה הבאה:",
    emailPlaceholder: "הזנת כתובת דוא\"ל שלך",
    mailNotValid: "כתובת המייל אינה תקינה",
    prosSubmit: "הצטרפות",
    registration: "הרשמה להדרכה עתידית",
    success: "הסקר נשלח בהצלחה",
    errors: "חלה תקלה בשליחת הסקר. עימכם הסליחה :(",
    footerTitle: "אנחנו תמיד זמינים בשבילך:",
    pleaseWait: "נא להמתין..."
  }
};
