import React from 'react';
import { string, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable /*, toJS*/ } from 'mobx';
import { translate } from 'react-polyglot';
import ReactModal from 'react-modal';
import {
  addMissingDetails,
  checkOtp,
  doLogin,
  doRestorePassword,
  getLoginState,
  keyDownEvt,
  notMyDetails,
  resendOtpCode,
  setField,
} from './loginUtil';
import LoginForm from './LoginForm';
import LoginLeft from './LoginLeft';
import ForgotPassword from './ForgotPassword';
import MissingDetails from './MissingDetails';
import NotMe from './NotMe';
import SendOtp from './SendOtp';

import './LoginDialog.scss';

export default
@translate()
@inject("accountStore")
@inject("routingStore")
@observer
class LoginDialog extends React.Component {
  static propTypes = {
    onCancel: func,
    register: func,
    fromItem: string,
    isOtp: bool
  };

  @observable userName = '';
  @observable password = '';
  @observable rememberMe = false;
  @observable email = '';
  @observable cellNum = '';
  @observable otpDestination = '';
  @observable otpDefinitions = 'sms';  // stands for the destination defined in CustomerCare (if not, default is sms)
  @observable otpChangedByUser = false;
  @observable authCode = '';
  @observable restore = false;
  @observable validationErrors = '';
  @observable sentMessage = '';
  @observable sentError = false;

  state = {
    login: true,
    forgotPassword: false,
    addDetails: false,
    notMe: false,
    otp: false,
    activeState: 2,
    width: window.innerWidth,
  };

  componentDidMount() {
    //console.log('LoginDialog mounted');
    ReactModal.setAppElement("#root");
    window.addEventListener("resize", this.updateDimensions);
    const { accountStore, isOtp } = this.props
    if (isOtp) {
      this.onStateChange('otp')
    }
    // check localStore for defined otp destination
    const otpDestinationDef = localStorage.getItem('tenders_OtpDestinationDef');
    if (otpDestinationDef) {
      this.otpDefinitions = otpDestinationDef;
      this.otpDestination = otpDestinationDef === 'email' ? 'email' : 'sms';
    }
    this.setSentMessage('', false); // clear sent message and error when component mounts (also after close and reopen)
    accountStore.clearErrors();   // do the same with the accountStore errors - when the component mounts, clear previous errors
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ ...this.state, width: window.innerWidth });
  };

  onStateChange = (stateName) => {
    this.setSentMessage('', false);  // clear sent message to clear the label in the component that relies on it
    const { accountStore } = this.props
    accountStore.clearErrors(); // clear also error from accountStore as the component state changes and the view with it
    if (stateName === 'login' && this.otpDestination === '') {
      this.otpDestination = 'sms' // when login view is toggled, reset the otp destination to sms if it was not set
    }
    else if (stateName === 'notMe' && accountStore.otpStatus.OtpDestination === 'email') {
      this.otpDestination = 'email' // when notMe view is toggled, set the otp destination to email if it was send to email
    }
    const stateObj = getLoginState(stateName);
    this.setState(stateObj);
  };

  doSetState = (stateObj) => {
    this.setState(stateObj);
  };

  setValidationError = (error) => {
    this.validationErrors = error;
  };

  setSentMessage = (message, isError) => {
    this.sentMessage = message;
    this.sentError = isError;
  };

  updateField = (e) => {
    setField(e, this);
  };

  setOtp = (otp) => {
    this.authCode = otp;
  };

  onKeyDown = (e, which) => {
    keyDownEvt(e, which, this);
  };

  login = () => {
    if (this.otpDestination === '') this.otpDestination = 'sms'   // sms is the default if none is selected
    doLogin(
      this.props,
      this.userName,
      this.password,
      this.rememberMe,
      this.otpDestination,
      this.otpChangedByUser,
      this.doSetState
    );
  };

  changeOtpDestination = (checked) => {
    this.otpDestination = checked ? 'email' : 'sms';
    this.otpChangedByUser = true;
    //console.log('otpDestination', this.otpDestination);
    console.log('otpChangedByUser', this.otpChangedByUser);
  }

  addDetails = () => {
    addMissingDetails(
      this.props,
      this.otpDestination,
      this.cellNum,
      this.doSetState,
      this.setValidationError
    );
  };

  notMe = () => {
    notMyDetails(
      this.props,
      this.otpDestination,
      this.email,
      this.cellNum,
      this.setValidationError,
      this.setSentMessage
    );
  };

  resendCode = (destination) => {
    resendOtpCode(this.props, this.setSentMessage, destination, this.doSetState, this.changeOtpDestination);
  };

  sendOtp = () => {
    checkOtp(
      this.props,
      this.authCode,
      this.setValidationError,
      this.setSentMessage
    );
  };

  restorePassword = () => {
    //console.log('restore')
    doRestorePassword(
      this.props,
      this.userName,
      this.email,
      this.setSentMessage
    );
  };

  render() {
    const { accountStore, onCancel, register, t } = this.props;
    const { login, forgotPassword, addDetails, notMe, otp, activeState } =
      this.state;
    const {
      userName,
      password,
      email,
      sentMessage,
      sentError,
      validationErrors,
      cellNum,
      authCode,
    } = this;
    const isMobile = this.state.width < 768;

    const rightComponent = (isMobile) => (
      <div styleName="right">
        {login ? (
          <LoginForm
            accountStore={accountStore}
            error={accountStore.errorMessage}
            userName={userName}
            password={password}
            changeOtpDestination={this.changeOtpDestination}
            updateField={this.updateField}
            onKeyDown={this.onKeyDown}
            login={this.login}
            onStateChange={this.onStateChange}
            t={t}
            isMobile={isMobile}
            emailSwitchOn={this.otpDefinitions === 'email'}
          />
        ) : forgotPassword ? (
          <ForgotPassword
            accountStore={accountStore}
            userName={userName}
            email={email}
            updateField={this.updateField}
            onKeyDown={this.onKeyDown}
            restore={this.restorePassword}
            onStateChange={this.onStateChange}
            sentMessage={sentMessage}
            sentError={sentError}
            t={t}
          />
        ) : addDetails ? (
          <MissingDetails
            accountStore={accountStore}
            error={
              validationErrors !== ''
                ? validationErrors
                : accountStore.errorMessage
            }
            cellNum={cellNum}
            updateField={this.updateField}
            onKeyDown={this.onKeyDown}
            addDetails={this.addDetails}
            onStateChange={this.onStateChange}
            t={t}
          />
        ) : notMe ? (
          <NotMe
            accountStore={accountStore}
            error={
              validationErrors !== ''
                ? validationErrors
                : accountStore.errorMessage
            }
            email={email}
            cellNum={cellNum}
            updateField={this.updateField}
            onKeyDown={this.onKeyDown}
            notMe={this.notMe}
            sentMessage={sentMessage}
            sentError={sentError}
            onStateChange={this.onStateChange}
            t={t}
          />
        ) : otp ? (
          <SendOtp
            accountStore={accountStore}
            error={
              validationErrors !== ''
                ? validationErrors
                : accountStore.errorMessage
            }
            authCode={authCode}
            updateField={this.updateField}
            onKeyDown={this.onKeyDown}
            sendOtp={this.sendOtp}
            resendCode={this.resendCode}
            sentMessage={sentMessage}
            sentError={sentError}
            onStateChange={this.onStateChange}
            t={t}
            isMobile={isMobile}
          />
        ) : null}
      </div>
    );

    return (
      <ReactModal
        isOpen={true}
        onRequestClose={onCancel}
        className="reveal-custom reveal-custom-login"
        overlayClassName="reveal-overlay-custom"
      >
        <div styleName="container">
          <div className="closeBtn" onClick={onCancel}>
            <div className="line" />
            <div className="line" />
          </div>
          {isMobile && (
            <div styleName="tabs">
              <div
                onClick={() => this.setState({ activeState: 2 })}
                styleName={`tab ${activeState === 2 ? 'active-tab' : ''}`}
              >
                <span>{t("loginForm.login")}</span>
              </div>
              ¸
              <div
                onClick={() => this.setState({ activeState: 1 })}
                styleName={`tab ${activeState === 1 ? 'active-tab' : ''}`}
              >
                <span>{t("loginForm.purchase")}</span>
              </div>
            </div>
          )}
          {!isMobile && (
            <div styleName="left">
              <LoginLeft onCancel={onCancel} register={register} t={t} />
            </div>
          )}
          {isMobile && activeState === 1 && (
            <div styleName="left">
              <LoginLeft
                onCancel={onCancel}
                register={register}
                t={t}
                isMobile={isMobile}
              />
            </div>
          )}
          {isMobile && activeState === 2 && rightComponent(isMobile)}
          {isMobile && (
            <div styleName="copyRight">{t('loginForm.copyRight')}</div>
          )}
          {!isMobile && rightComponent()}
        </div>
      </ReactModal>
    );
  }
}
