import { useState, useEffect } from "react";
import remove from "lodash/remove";
import find from "lodash/find";
import { addToFavorites, clearCache } from "common/services/apiService";

/*
//old way:
export function setCheckedStatus(checked, value, isFavorite, push, cut) {
  if (checked) {
    push(value, isFavorite)
  }
  else {
    cut(value)
  }
}
*/

export function setCheckedStatus(checked, value, isFavorite, push, cut) {
  cut(value); //remove
  push(checked, value, isFavorite); //push again checked\unchecked
}

export function setFavStatus(tenderID, add, isIn, push, cut) {
  const action = add ? "Favorite_add" : "Favorite_del";
  addToFavorites(action, [tenderID]);
  clearCache();
  const found = isIn(tenderID); //for checked state
  //if (found) {
  //old way...: if item is in checkedItems array, need to update its fav state;
  //new way: add it anyway because it was touched
  cut(tenderID); //remove
  //add the item again with new fav state
  push((found && found.checked) || false, tenderID, add);
  //}
}

export function getImageUrl(fileName) {
  const cleanFileName = fileName
    .replace(/\\/g, "/")
    .replace(/\/\/int_fs\/Clips/g, "");
  const url =
    cleanFileName.indexOf("ColorClp") > -1 ||
      cleanFileName.indexOf("ClipsPdf") > -1
      ? `https://www.ifat.com/MediaSite/CustomerMedia/ClipsImages${cleanFileName}`
      : "";
  //console.log('getImageUrl', url)
  return url;
}

export function extractLabel(value, more) {
  const arr = value.split(",");
  const label =
    arr.length > 2
      ? `${arr.slice(0, 2).join(",")} ${more} ${arr.length - 2}`
      : value;
  return label;
}

export function randomNumber(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

const getDimensions = () => {
  const { innerWidth, innerHeight } = window;
  return {
    width: innerWidth,
    height: innerHeight,
  };
};

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState(getDimensions());
  useEffect(() => {
    const handleChange = () => {
      setDimensions(getDimensions());
    };
    window.addEventListener("resize", handleChange);
    return () => window.removeEventListener("resize", handleChange);
  }, []);
  return dimensions;
};

export const getMetaDataCustom = (id, mode) => {
  if (mode === 'cat') {
    switch (id) {

      case '2':
        return {
          metaDesc: "יפעת מכרזים מציעים מערכת למציאת מכרזי בניה המיועדים לכל העוסקים בענף. החברה מספקת מגוון שירותים המסייעים לקבלנים להגדיל את הסיכוי לקבל מכרז בניה, לפרטים",
          metaTitle: "מכרזי בניה: צפייה והגשת מועמדות למכרזים הכי חדשים | יפעת מכרזים"
        }
      case '40':
        return {
          metaDesc: "מכרזים לעבודות חשמל כוללים תחומים של פיקוח, אחזקה ותכנון של הפרויקט. יפעת מכרזים מציעים ליווי אישי וגישה מהירה לכל מכרזי עבודות החשמל המתפרסמים, לפרטים",
          metaTitle: "מכרזים לעבודות חשמל: פרסום הזדמנויות עסקיות לקבלנים | יפעת מכרזים"
        }
      case '26': {
        return {
          metaDesc: "מכרזי הסעות נערכים על ידי משרדי ממשלה, גופים ממשלתיים, מוסדות חינוך ועוד. יפעת מכרזים מציעים פרסום עדכני של מכרזים להסעות ומספקים ליווי אישי, למידע נוסף",
          metaTitle: "מכרזי הסעות: מאגר מכרזים להסעות לעסקים וארגונים | יפעת מכרזים"
        }
      }
      default:
        return false
    }
  }
  else {
    switch (id) {
      case '1':
        return {
          metaDesc: "יפעת מכרזים מרכזת עבורכם מכרזי ממשלה בלעדיים ופומביים. הצטרפו גם אתם לעסקים וארגונים שמקבלים מידע ייחודי על מכרזים ממשלתיים והזדמנויות עסקיות נוספות בתחום",
          metaTitle: "מכרזים ממשלתיים: איתור הזדמנויות בלעדיות לעסקים | יפעת מכרזים"
        }
      case '2':
        return {
          metaDesc: "יפעת מכרזים מציעים לכם שירותים בלעדיים המאפשרים לכם להיחשף בזמן אמת לכל סוגי המכרזים של משרד הביטחון. מוזמנים להצטרף למאות עסקים ארגונים ולקבל מידע ייחודי",
          metaTitle: "משרד הביטחון מכרזים: רשימת המכרזים המלאה והבלעדית! | יפעת מכרזים"
        }
      case '3':
        return {
          metaDesc: "רשות מקרקעי ישראל היא הגוף האחראי לניהול קרקעות בבעלות המדינה. יפעת מכרזים מציעים פרסום של מכרזי מינהל מקרקעי ישראל ויצירת הזדמנויות לעסקים וארגונים, לפרטים",
          metaTitle: "מכרזי מינהל מקרקעי ישראל: אתר המכרזים המוביל במדינה | יפעת מכרזים"
        }
      case '4':
        return {
          metaDesc: "יפעת מכרזים מציעים שירותים מתקדמים המאפשרים לכם להיחשף למגוון מכרזי משרד החינוך. אפליקצייה ייעודית שתספק לכם מידע רלוונטי על כל מכרז חדש שנפתח במשרד החינוך",
          metaTitle: "מכרזי משרד החינוך: מכרזים ממשלתיים של משרד החינוך | יפעת מכרזים"
        }
      case '5':
        return {
          metaDesc: "חברת החשמל היא חברה ממשלתית וציבורית שנוסדה בשנת 1923. יפעת מכרזים מספקים שירות ייחודי לפרסום מכרזי חברת החשמל ויצירת הזדמנויות עסקיות. לפרטים נוספים לחצו",
          metaTitle: "מכרזים חברת החשמל: פרסום מגוון הזדמנויות עסקיות | יפעת מכרזים"
        }

      case '6':
        return {
          metaDesc: "יפעת מכרזים מזמינה אתכם להיחשף למכרזים של משטרת ישראל העדכניים והרלוונטים. החברה מציעה אפליקציה ייחודית ושירותים מקצועיים שיגדילו את הסיכויים לקבלת המכרז",
          metaTitle: "משטרת ישראל מכרזים: פרסום מידע על מכרזים חדשים | יפעת מכרזים"
        }

      case '8':
        return {
          metaDesc: "יפעת מכרזים מרכזת עבורכם את מכרזי משרד הבריאות העדכניים ביותר. החברה מציעה שירות מקצועי וליווי אישי הכולל קבלת התראות על המכרזים הרלוונטים עבורכם, לחצו כאן",
          metaTitle: "מכרזים משרד הבריאות: מאגר מכרזים של משרד הבריאות | יפעת מכרזים"
        }
      case '9':
        return {
          metaDesc: "יפעת מכרזים מציעים ממשק נוח בו תוכלו למצוא בכל רגע נתון מספר רב של מכרזים פעילים של משרד הרווחה. החברה מציעה ליווי אישי עד לקבלת המכרז במשרד הרווחה, לפרטים",
          metaTitle: "מכרזים משרד הרווחה: פרסום של המכרזים העדכניים ביותר | יפעת מכרזים"
        }

      case '10':
        return {
          metaDesc: "משרד הבינוי והשיכון הוא המשרד האחראי על תחום הדיור והבנייה בישראל. יפעת מכרזים מספקים מידע על מכרזי משרד השיכון ומציעים ליווי אישי עי סוכן חכם, למידע נוסף",
          metaTitle: "משרד הבינוי והשיכון מכרזים: מגוון מכרזים עדכניים | יפעת מכרזים"
        }
      case '101':
        return {
          metaDesc:'מחפשים את הפרסום העדכני ביותר למכרזי הרשויות המקומיות? יפעת מכרזים בעלי ניסיון של יותר מ-40 שנה באיתור ופרסום של מכרזי הרשויות המקומיות להגשת מועמדות לחצו כאן',
          metaTitle:'מכרזי הרשויות המקומיות: פרסום המידע העדכני ביותר | יפעת מכרזים',
        }
      case '102':
        return {
          metaDesc:'מחפשים את המכרזים העדכניים ביותר של עיריית באר שבע? יפעת מכרזים מספקים עבורכם מאגר מידע למכרזי עיריית באר שבע המתעדכן באופן שוטף, להגשת מועמדות לחצו כאן',
          metaTitle:'מכרזים עיריית באר שבע: חשיפה למאות הזדמנויות עסקיות | יפעת מכרזים',
        }
      case '103':
        return {
          metaDesc:'יפעת מכרזים היא החברה המובילה בישראל, המספקת פרסומים שוטפים ועדכניים של מכרזי עיריית הוד השרון ומציעה שירות ליווי אישי של סוכן חכם. למידע נוסף לחצו כאן',
          metaTitle:'מכרזים עיריית הוד השרון: פרסומים שוטפים ועדכניים | יפעת מכרזים',
        }
      case '104':
        return {
          metaDesc:'מכרזים של עיריית נתיבות עשויים להיות ההזדמנות העסקית שתאפשר לכם להגדיל את ההכנסות של העסק. יפעת מכרזים מציעים פרסום עדכני ושוטף של מכרזי עיריית נתיבות, לחצו כאן',
          metaTitle:'מכרזים עיריית נתיבות: פרסום מגוון הזדמנויות עסקיות | יפעת מכרזים',
        }
      case '105':
        return {
          metaDesc:'יפעת מכרזים בעלי ניסיון של יותר מ-40 שנה בפרסום מכרזים ויצירת הזדמנויות עסקיות, מציעים מידע עדכני ושוטף על פרסום מכרזי מועצה אזורית שדות נגב, לפרטים נוספים לחצו כאן',
          metaTitle:'מכרזים מועצה אזורית שדות נגב: פרסום מידע עדכני | יפעת מכרזים',
        }
      case '106':
        return {
          metaDesc:'יפעת מכרזים מרכזת עבורכם את הפרסומים העדכניים ביותר למכרזי עיריית רמת השרון. באתר תוכלו להנות משירות של סוכן חכם, ליצור הזדמנויות עסקיות ולהגדיל את ההכנסה',
          metaTitle:'מכרזים עיריית רמת השרון: פרסום מגוון הזדמנויות עסקיות | יפעת מכרזים',
        }
      case '107':
        return {
          metaDesc:'יפעת מכרזים מרכזת עבורכם את המכרזים של עיריית סכנין המתעדכנים באופן שוטף. הגשת מועמדות למכרז של עיריית סכנין מייצרת הזדמנויות עסקיות שיגדילו את ההכנסות, לפרטים',
          metaTitle:'מכרזים עיריית סכנין: פרסום עדכני של מכרזים פומביים | יפעת מכרזים',
        }
      case '108':
        return {
          metaDesc:'יפעת מכרזים מרכזת עבורכם את הפרסומים העדכניים ביותר למכרזי הובלות: הובלה במשאיות, הובלת מזון בקירור ועוד. מוזמנים להכנס לאתר ולהנות משירות של סוכן חכם>>',
          metaTitle:'מכרזי הובלות: מאגר מידע עדכני ליצירת הזדמנויות עסקיות | יפעת מכרזים',
        }
      case '109':
        return {
          metaDesc:'יפעת מכרזים מרכזת עבורכם את כל הפרסומים העדכניים של מכרזי מפעל הפיס ומציעה ליווי אישי של סוכן חכם. למידע על המכרזים המתפרסמים ויצירת הזדמנויות עסקיות לחצו כאן',
          metaTitle:'מפעל הפיס מכרזים: מגוון הזדמנויות עסקיות עדכניות | יפעת מכרזים',
        }
      case '110':
        return {
          metaDesc:'יפעת מכרזים מרכזת עבורכם פרסום מכרזים לעבודות עפר המתעדכנים באופן שוטף: מכרזי עבודות עפר בצפון, מכרזי עבודות עפר ופיתוח ועוד. ליצירת הזדמנויות עסקיות לחצו כאן',
          metaTitle:'מכרזי עבודות עפר: פרסום מכרזים ממשלתיים ופומביים | יפעת מכרזים',
        }
      case '111':
        return {
          metaDesc:"ארגון הג'וינט הוא ארגון צדקה יהודי אמריקאי שנוסד בתחילת המאה העשרים שסייע במלחמות שונות. חברת יפעת מכרזים מספקת פרסומים שוטפים על מכרזי ג'וינט ישראל, לפרטים",
          metaTitle:"מכרזי ג'וינט ישראל: מאגר פרסום מכרזים עדכני | יפעת מכרזים",
        }
      case '112':
        return {
          metaDesc:'יפעת מכרזים מרכזת את פרסום מכרזי העיצוב הגרפי במקום אחד. החברה בעלת יותר מ-40 שנות ניסיון ומספקת שירות של סוכן חכם שיציג לכם הזדמנויות עסקיות בלעדיות, לחצו כאן',
          metaTitle:'מכרז עיצוב גרפי: פרסום שוטף של הזדמנויות עסקיות | יפעת מכרזים',
        }
      case '113':
        return {
          metaDesc:'יפעת מכרזים מספקת את המידע העדכני ביותר ומפרסמת את מכרזי קופת חולים מאוחדת באופן בלעדי, כולל שירות של סוכן חכם שיתריע על פרסום המכרזים הרלוונטים עבורכם. לפרטים',
          metaTitle:'קופת חולים מאוחדת מכרזים: עדכונים ופרסומים שוטפים | יפעת מכרזים',
        }
      case '114':
        return {
          metaDesc:'קופת חולים לאומית מפרסמת מכרזים בכל רחבי הארץ ומציעה מגוון הזדמנויות עסקיות לבעלי שירות. יפעת מכרזים מביאה את מכרזי קופת חולים לאומית העדכניים ביותר, למידע נוסף',
          metaTitle:'קופת חולים לאומית מכרזים: פרסום מכרזים פעילים | יפעת מכרזים',
        }
      default:
        return false
    }
  }
}