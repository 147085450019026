import React, {Component} from 'react'
import {observer} from 'mobx-react'
import {observable} from 'mobx'
import {translate} from 'react-polyglot'
import {getHomeJSON} from 'common/services/apiService'
import Footer from 'common/components/Footer'
import Adsense from "common/components/Adsense/Adsense";
import SmallContactForm from 'common/components/SmallContactForm'
//import ContactAction from 'common/components/ContactAction'
import DocumentMeta from 'react-document-meta'
import {getMetaData} from 'common/utils/meta'
import GTAG from 'common/utils/gtag'
import { vIcon } from 'common/style/icons'

import  './about.scss'

/*const req = require.context('common/style/icons/', false)
const vIcon = req('./vIcon.svg').default
*/
export default
@translate()
@observer
class About extends Component {

  @observable data;

  componentDidMount() {
    getHomeJSON('About', 'about').then(res => {
      this.data = res
    })
    GTAG.trackPage('About', 'about')
  }

  render() {
    const {t} = this.props
    const {data} = this
    const meta = getMetaData(t('meta.aboutTitle'), t('meta.aboutDesc'), t('meta.aboutKeywords'))

    return (
      <div>
        <DocumentMeta {...meta} />
        <section id="articles">
          <div styleName="adsense-container-desktop" style={{height: `${0.75*window.innerHeight}px`}} >
            <Adsense
              style={{
                  display: "block",
                  width: "160px",
                  height: "600px",
                  // position: "absolute",
                  // border:"1px solid black",
                  // left: "0",
                  // top: "20px",
                  // zIndex: "99999999",
              }}
              slot="4313418986"
              googleAdId="ca-pub-7408487462494237"
            />
          </div>
          <div className="row" style={{width: '80%', maxWidth: '155rem'}}>
            <div className="large-8 small-12 columns">
              <h1 styleName="title">{t('about.title')}</h1>
              <h2 styleName="subtitle">{t('about.subTitle')}</h2>
              {data &&
              <div styleName="content">
                <div styleName="item"><p dangerouslySetInnerHTML={{__html: data.line1}}></p></div>
                <div styleName="item"><p dangerouslySetInnerHTML={{__html: data.line2}}></p></div>
                <div styleName="item"><p dangerouslySetInnerHTML={{__html: data.line3}}></p></div>
                <div styleName="item"><p dangerouslySetInnerHTML={{__html: data.line4}}></p></div>
                <div styleName="item"><p dangerouslySetInnerHTML={{__html: data.line5}}></p></div>

                <div style={{marginBottom: '50px'}}>
                  <div styleName="customer_types_title">{data.customers.title}</div>
                  {
                    data.customers.items.map((customer, index) => <div key={index} styleName="customer_types"><img src={vIcon} />{customer.title}</div>)
                  }
                </div>
              </div>
              }
            </div>
            <div className="large-4 small-12 columns">
              <SmallContactForm />
            </div>
          </div>
        </section>
        <Footer />
        {/*<ContactAction />*/}
      </div>
    )
  }
}
