import React from "react";
import { logoSrc } from "common/style/icons";
import OtpInput from "common/components/OtpInput";

import "./LoginDialog.scss";

/*const req = require.context('common/style/icons/', false)
const logo = req('./logo.png').default
*/

const SendOtp = ({
  accountStore,
  error,
  authCode,
  updateField,
  onKeyDown,
  sendOtp,
  resendCode,
  sentMessage,
  sentError,
  onStateChange,
  t,
  isMobile,
}) => {
  // const sentTo = accountStore.otpStatus.SentTo.replace(/\*/g, '')
  const sentTo = "";
  const sentDestination = accountStore.otpStatus.OtpDestination === "email" ? "Email" : "SMS";

  const setOtp = (otp) => {
    const evt = {};
    evt.target.name = "authCode";
    evt.target.value = otp;
    updateField(evt);
  };

  return (
    <div styleName="loginForm otp">
      <div styleName="logo">
        <img src={logoSrc} />
      </div>
      <div styleName="innerContainer">
        <div>
          <div styleName="heading">{t("otp.otpTitle", {sentDestination})}</div>
          <div styleName="divider"></div>
        </div>
        <div>
          {error != null && <div styleName="error_box">{error}</div>}
          {sentMessage !== "" && (
            <div styleName={sentError ? "error_box" : "error_box green"}>
              {sentMessage}
            </div>
          )}
          <div styleName="inputContainer">
            <div styleName="label" style={{ marginBottom: "3rem" }}>
              {sentDestination === "SMS" ? t("otp.sendOtpEx") : t("otp.sendOtpExEmail")}
            </div>
            {/* {isMobile && <OtpInput onChange={setOtp} />} */}
            <input
              styleName="input"
              type="number"
              name="authCode"
              value={authCode}
              onChange={updateField}
              onKeyDown={(e) => onKeyDown(e, "sendOtp")}
            />
          </div>
          {!isMobile && (
            <>
            <div styleName="bottom" style={{marginBottom: '0.5rem', marginTop: '2rem'}}>
              <div styleName="link" style={{fontSize: '1.5rem', justifyContent: 'flex-start'}}>
              {t("otp.didNotReceiveMessage")}
              </div>
            </div>
            <div styleName="bottom" style={{marginBottom: 0}}>
              <div styleName="link" style={{justifyContent:'flex-start', marginTop: '0.5rem'}}>
                <a
                  onClick={() => onStateChange("notMe")}
                >
                  {(sentDestination === 'SMS' ? t("otp.notMyNumber") : t("otp.notMyEmail"))}
                </a>
              </div>
            </div>
            </>
          )}
          <div styleName="bottom flexColumn" style={{marginBottom: '7.3rem'}}>
            <div styleName='some-text'>{t("otp.didNotReceiveMessage")}</div>
            <div styleName="link resend-code" style={{justifyContent:'flex-start'}}>
              <a onClick={resendCode}>{t("otp.resendCode")}</a>
            </div>
            { sentDestination === "SMS" &&
            <div styleName="link resend-code">
              <a onClick={() => resendCode('email')}>{t("otp.resendByEmail")}</a>
            </div>}
          </div>
        </div>
        <button styleName="btn" onClick={sendOtp}>
          {t("otp.login")}
        </button>
      </div>
    </div>
  );
};

export default SendOtp;
