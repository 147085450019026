import React from 'react'
import { logoSrc } from 'common/style/icons'

import './LoginDialog.scss'

/*const req = require.context('common/style/icons/', false)
const logo = req('./logo.png').default
*/

const MissingDetails = ({accountStore, error, cellNum, updateField, onKeyDown, addDetails, onStateChange, t}) => {
  const name = decodeURIComponent(accountStore.otpStatus.ContactName).replace(/\+/g, ' ')
  //console.log('status', accountStore.otpStatus.OtpDestination);
  let message = t('otp.missingDetailsPhone')
  let inputType = 'number'
  if (accountStore.otpStatus.OtpDestination === 'email') {
    message = t('otp.missingDetailsEmail')
    inputType = 'email'
  }
  
  return <div styleName='loginForm'>
    <div styleName='logo'>
      <img src={logoSrc} />
    </div>
    <div styleName='innerContainer'>
      <div>
        <div styleName='heading'>{t('otp.detailsTitle')}</div>
        <div styleName='divider'></div>
      </div>
      <div>
        {error != null && error != '' &&
          <div styleName="error_box">{error}</div>
        }
        <div styleName='inputContainer'>
          <div styleName='label' style={{marginBottom: '3rem'}}>{t('otp.missingDetailsEx', {name})}</div>
          <div styleName='label' style={{marginBottom: '3rem'}}>{message}</div>
          <input 
            styleName='input'
            type={inputType}
            name="cellNum"
            value={cellNum}
            onChange={updateField}
            onKeyDown={e => onKeyDown(e, 'addDetails')}
          />
        </div>
        <div styleName='bottom'>
          <div styleName='link'><a onClick={() => onStateChange('login')} style={{paddingRight: '20px'}}>{t('otp.backToLogin')}</a></div>
        </div>
      </div>
      <button styleName='btn' onClick={addDetails}>{t('otp.addDetails')}</button>
    </div>
  </div>
}

export default MissingDetails